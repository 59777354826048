.forgotPassword {
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;

  h3{
    color: red;
  }

  .forgotPasswordForm {
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    max-height: 225px;
    padding: 25px;
    max-width: 800px;
    margin-top: 10vh;



    .inputWrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      input {
        width: 100%;
        font-size: 18px;
        height: 35px;
      }

      label {
        font-size: 18px;
      }
    }

    .emailWrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      input {
        height: 35px;
        border-radius: 3px;
        font-size: 18px;
        border: 1px solid black;
      }

      button {
        height: 35px;
        border-radius: 3px;
        background-color: $LG-AccentBlue-2;
        font-size: 18px;
        color: white;
        cursor: pointer;
      }

      button:disabled {
        background-color: gray;
        cursor: not-allowed;
      }
    }

    .saveButton {
      width: 100%;
      margin-top: 10px;
      height: 35px;
      border-radius: 3px;
      background-color: $LG-AccentBlue-2;
      font-size: 18px;
      color: white;
      cursor: pointer;
    }

    .saveButton:disabled {
      background-color: gray;
      cursor: not-allowed;
    }
  }
}

.reset-success {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: $LG-AccentGreen-2;
    font-size: 24px;
  }

  a {
    font-size: 24px;
  }
}
