.login-grid {
  display: grid;
  display: -ms-grid;

  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 1fr;

  position: relative;

  margin: 50px;

  box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.75);

  @media (max-width: 1024px) {
    margin: 0;
    border: 0;

    grid-template-columns: 1fr;

    -webkit-box-shadow: none;
  }

  @keyframes error-message {
    from {
      transform: translateY(-100px);
      -webkit-transform: translateY(-100px);
      -ms-transform: translateY(-100px);
    }

    65% {
      transform: translateY(15px);
      -webkit-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
    }

    to {
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
    }
  }

  @media only screen and (max-width: 70em) {
    .login-form-bad-request {
      left:0 !important;
      right:0 !important;
      margin-left:auto !important;
      margin-right:auto !important;
      max-width: 61.1% !important;
      font-size: 13px !important;
      align-items: center !important;
      height: 4.1em !important;
      border-radius: 7px !important;

      .login-form-bad-request-text {
        margin-bottom: 4px;
      }
    }
  }

  .login-form-bad-request {
    position: absolute;

    border-radius: 15px;

    background-color: #af0606;
    color: whitesmoke;

    padding: 15px;
    margin: 10px;

    max-width: 80%;

    display: -ms-grid;

    display: grid;
    -ms-grid-columns: 1fr auto;
    grid-template-columns: 1fr auto;

    -webkit-animation-name: error-message;

    animation-name: error-message;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;

    left: 16.4%;

    z-index: 1;

    .login-form-bad-request-close-button {
      background-color: transparent;
      margin-left: 3px;

      text-align: center;

      height: 25px;
      width: 25px;
    }
  }

  @media only screen and (min-width: 1600px) {
    .login-form-bad-request {
      left: 18.2%;
    }
  }

  .login-form-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;

    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

    -ms-flex-direction: column;

    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .close-me {
      position: absolute;
      font-size: 25px;

      left: 15px;
      top: 10px;

      text-decoration: none;

      color: inherit;

      cursor: pointer;

      background-color: white;
    }

    .login-form {
      padding: 25px;

      .login-form-header {
        color: grey;
        font-weight: normal;
        margin: 40px 0;
        text-align: center;
      }

      .form-input-container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 20px 1fr;
        grid-template-columns: 1fr 1fr;

        grid-gap: 20px;

        margin-top: 25px;

        @media (max-width: 1024px) {
          grid-template-columns: 1fr;
          -ms-grid-columns: 1fr;
          margin-top: 0;
          justify-items: center;
        }

        .form-input-wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          width: 100%;
          background-color: whitesmoke;
          border-radius: 5px;

          @media (max-width: 1024px) and (min-width: 768px) {
            width: 80%;
          }

          .form-icon {
            font-size: 22px;
          }

          .form-input {
            width: 80%;
            outline: none;
            border: none;

            padding: 5px;
            margin: 7px 0;
            margin-bottom: 7px;

            background-color: inherit;
            border-bottom: 1px solid #d2d2d2;
          }
        }
      }

      .form-bottom-wrapper {
        display: grid;
        display: -ms-grid;

        grid-template-columns: 1fr 1fr;
        -ms-grid-columns: 1fr 1fr;

        width: 100%;

        a {
          margin: 10px 0;

          text-decoration: none;

          color: green;
        }

        .form-check-wrapper {
          padding: 10px;
          font-size: 20px;

          input {
            width: 15px;
            height: 15px;

            margin-right: 15px;
          }
        }

        .disclaimer {
          text-align: justify;
          margin: 20px 0;
        }

        .form-bottom-left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }

        .form-bottom-top {
          text-align: center;

          display: -ms-grid;

          display: grid;

          grid-gap: 15px;
        }
      }

      .btn-wrapper {
        margin-top: 15px;

        -ms-flex-item-align: center;

        -ms-grid-row-align: center;

        align-self: center;
        -ms-grid-column-align: right;
        justify-self: right;

        .btn {
          background-color: green;
          color: white;

          width: 100px;
          height: 50px;

          border-radius: 5px;

          font-size: 20px;
        }
      }

      .stacked {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
      }
    }
  }

  .login-form-wrapper,
  img {
    min-width: 300px;
    width: 100%;
    height: 75vh;

    @media (max-width: 1024px) {
      border: 0;
    }
  }

  img {
    outline: none;

    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  outline: none;
}
