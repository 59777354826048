$text-color: black;

.formContainer {

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 20px;
  background: linear-gradient(45deg, #52919a, #0a4e57);

  @media (max-width: 44em) {
    padding: 0;
    background: none;
    margin-top: 15px;
  }

  .formWrapper {
    width: 790px;
    background: #fff;
    border-radius: 2px;
    padding: 55px 95px 68px 95px;

    @media (max-width: 44em) {
     
      width: 100%;
      padding: 0;
    }

    .form {
      width: 100%;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .formTitle {
        width: 100%;
        display: block;

        font-size: 30px;
        color: $text-color;
        font-weight: bold;

        line-height: 1.2;
        text-align: center;

        padding-bottom: 30px;
      }

      .formSubtitle {
        width: 100%;
        display: block;

        font-size: 20px;
        color: $text-color;
        line-height: 1.2;
        text-align: center;
        padding-bottom: 45px;

        @media (max-width: 44em) {
          padding-bottom: 15px
        }
      }

      .formText {
        font-size: 1.1em;

        margin: 2em;

        line-height: 1.5em;

        text-align: justify;
      }

      .label {
        font-size: 13px;
        color: #555555;
        line-height: 1.5;
        text-transform: uppercase;
        width: 100%;
        padding-bottom: 11px;

        @media (max-width: 44em) {
          text-align: center;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 10px
        }

      }

      .smallerLabel {
        width: calc((100% - 40px) / 2);
      }

      .inputWrapper {
        width: 100%;

        border: 1px solid #e6e6e6;
        border-radius: 2px;

        margin-bottom: 10px;
        margin-top: 5px;

        .inputBox {
          display: block;

          width: 100%;
          height: 55px;

          background: transparent;

          font-size: 18px;
          color: $text-color;
          line-height: 1.2;

          padding: 0 25px;

          outline: none;
          border: none;
        }

        .fakeInputBox {
          padding: 1em;

          text-align: center;
        }
      }

      .smallerInputWrapper {
        width: calc((100% - 40px) / 2);
      }

      .mediumInputWrapper {
        width: calc((100% - 40px) / 1.25);
      }

      @media (max-width: 44em) {  
        .inputWrapper, .smallerInputWrapper, .mediumInputWrapper {
          width: 90%;
          margin: auto;
          margin-bottom: 5px;

          input {
            text-align: center;
          }
        }
      }

      .requirementsWrapper {
        .requirementsTitle {
          font-size: 1.2em;
          font-weight: 500;

          @media (max-width: 44em) {
            display: block;
            width: 100%;
            text-align: center;
          }
        }

        .requirementsUl {
          margin: 2.5% 10%;

          li {
            line-height: 1.5em;
          }
        }
      }

      .selectWrapper {
        padding: 0;
        margin: 0;

        width: calc((100% - 40px) / 3);
        height: 100%;

        border: 1px solid #ccc;
        border-radius: 3px;

        overflow: hidden;

        background-color: #fff;
        background: #fff;

        .selectStyle {
          padding: 5px 8px;
          width: 100%;

          border: none;
          box-shadow: none;

          &:focus {
            outline: none;
          }

          option {
            font-size: 1.2em;
          }
        }
      }

      .row {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: bold;
      }

      @media (max-width: 44em) { 
      .row {
        margin-top: 1em;
      }
      }

      .btn {
        width: calc((100% - 40px) / 3);

        background-color: transparent;

        padding: 10px 30px;
        margin: 0;

        border: 1px solid #ccc;
        border-radius: 3px;

        cursor: pointer;

        font-size: 13px;
        color: $text-color;

        &:focus,
        &active {
          box-shadow: inset 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
          outline: none;
        }
      }

      .submit {
        // TODO : BETTER COLOR FOR BUTTON
        background-color: lightskyblue;
        margin: auto;
        
        @media (max-width: 44em) {
          margin-top: 1em;
          margin-bottom: .5em;
        }
      }

      .hereToTakeUpSpace {
        width: 100%;
      }
    }
  }
}

// HELPER STYLE CLASSES

.bold {
  font-weight: bold;
}

.cursive {
  // TODO : CHANGE FONT FAMILY TO ACTUAL CURSIVE
  font-style: italic;
}

.centered {
  padding: 2em;
  text-align: center;
}
