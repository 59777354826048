.dashboard-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  padding: 1em 2em 5em 2em;

  @media (max-width: 44em) {
    padding: 0;
  }

  height: 100%;
  min-height: 82vh;

  margin: 0;

  color: #5c5d59;

  .dashboard-general-wrapper {
    margin-top: 15px;

    display: grid;
    grid-gap: 30px;

    div {
      background-color: white;
      border-radius: 1.25rem;
    }

    .dashboard-top {
      justify-self: center;

      height: fit-content;
      width: fit-content;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      @media (max-width: 44em) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);

      h1 {
        height: fit-content;
        min-height: 40px;

        text-align: center;

        font-size: 1.5em;
        cursor: pointer;

        @media (max-width: 44em) {
          font-size: 1.3em;
        }

        padding: 0.1em 0;
        margin: 0.9em 1em;

        border-bottom: 1px solid transparent;

        transition: 0.33s ease-in-out;

        &:hover {
          border-bottom: 1px solid black;
        }
       
      }

      .activeLink {
        border-bottom: 1px solid black;
      }
    }

    .dashboard-bottom {
      display: flex;
      flex-direction: column;

      justify-items: center;
      align-items: center;

      height: 100%;
      width: fit-content;

      min-width: 400px;
      max-width: 500px;
      width: 50vw;

      @media (max-width: 44em) {
        min-width: 350px;
      }

      justify-self: center;

      padding: 30px;

      box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1),
        0 10px 10px -5px rgba(0, 0, 0, 0.04);

      h2 {
        margin: 0;
        font-weight: normal;
      }
    }

    .form-container {
      font-size: 1.5rem;

      margin: auto;

      label {
        margin: auto;
        font-size: 1.25rem;
      }

      input {
        width: fit-content;

        font-size: 1.1rem;
        text-align: center;

        height: 1.5em;

        margin: auto;
        margin-bottom: 1em;

        padding: 0.5em 2em;

        outline: none;

        border: none;
        border-bottom: 1px solid black;
      }

      button {
        width: fit-content;

        margin: auto;
        margin-top: 1em;

        padding: 0.5rem 1.25rem;
        border-radius: 0.5rem;

        background-color: #5a67d8;

        color: white;
        font-weight: 700;

        cursor: pointer;

        outline: none;

        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }

    .registrations-container {
      background: none;

      padding: 0;

      box-shadow: none;

      .err {
        font-size: 1.5em;
        text-align: center;
      }

      .card {
        min-width: 400px;
        max-width: 500px;
        width: 50vw;
        height: fit-content;

        @media (max-width: 44em) {
          min-width: 350px;
        }

        background: white;

        margin: 10px 0 1em 0;
        padding: 1em;

        box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);

        border-radius: 0.5em;

        .card-top {
          padding: 1rem;

          .card-title {
            font-size: 1.25rem;
            font-weight: 700;
            margin: 0;
          }

          .card-subtitle {
            color: #4a5568;
            margin: 0;
            font-size: 1.1em;
            font-weight: 500;
          }
        }

        .card-bottom {
          display: grid;
          justify-items: center;
          align-items: center;

          .card-button {
            padding: 0.5rem 1.25rem;
            border-radius: 0.5rem;

            background-color: #5a67d8;

            color: white;
            font-weight: 700;

            cursor: pointer;

            outline: none;

            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
          }
        }
      }
    }
  }
}

// Tickets Tab Styles
.tickets {
  margin: auto;
  width: 90%;
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);

  .header {
    display: flex;
    justify-content: center;

    .title {
      text-align: center;
      font-size: 25px;
      width: 50%;
      border-bottom: 1px solid black;
      padding-bottom: 15px;
    }
  }

  .body {
    padding: 1em;
    padding-top: 0;

    @media (max-width: 44em) {
      text-align: center;
    }

    .current-raffle {
      display: flex;
      justify-content: center;

      span {
        font-size: 20px;
      }
    }

    .ticket-list {
      max-height: 120px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;

      li {
        width: 90px;
      }
    }
  }
}

.dashboard-header {
  width: 100%;
  text-align: center;
  padding: 20px;

  .horizontal-line {
      border: 1px solid #a3aed0;
      // height: 18px;
      margin-top: 8px;
  }

  .dashboard-shortcuts {
    display: flex;
    justify-content: space-around;
    font-size: 25px;
    
    .dashboard-shortcuts-a-tags {
      font-weight: bold;
      text-decoration: none;
      color: #1e266d;
    }
  }
}

@media (max-width: 470px) {
    .dashboard-header {

      .dashboard-shortcuts {
        font-size: 18px;
        text-align: start;
        display: grid;
        grid-template-columns: 170px 170px;
        grid-gap: 10px;
        
        .dashboard-shortcuts-a-tags {
          font-weight: bold;
          text-decoration: none;
          color: #1e266d;
    
        }
      }
    }
  }

.ads{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  .ad{
    width: auto;
    padding: 15px;
    @media only screen and (max-width: 500px) {
      padding: 0px;
    }
    img{
      width: auto;
      height: 100%;
      max-height: 400px;
      @media only screen and (max-width: 500px) {
        max-height: 250px;
      }
    }

  }
}

.dashboard-subscription-update-disclaimer {
  font-size: 20px;
  text-align: center;

  :first-child {
    margin-top: 50px;
  }
}

.events {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  h1{
    text-align: center;
    color:#1B3A63;
  }

  p{
    text-align: center;
    color: brown;
  }
}

.events .rbc-calendar {
  max-width: 95%;
  height: 800px;
  overflow-x: auto;
}

.events .rbc-time-view {
  overflow-y: auto;
  height: 100%;
}

.events ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
  color: royalblue;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 13px;
}

.events ul li {
  margin: 5px 0;
}
