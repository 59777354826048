.petition-component {
  background-color: #fff;

  width: 100%;
  height: 100%;

  padding: 25px 10px;
  margin: 0;

  display: grid;
  justify-content: center;
  grid-gap: 25px;
  grid-auto-columns: 50%;
  grid-auto-rows: 100px;

  @media (max-width: 500px) {
    grid-auto-columns: 70%;
  }

  .petition-link-wrapper {
    background-color: #707070;
    list-style: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.75);


    padding: 15px;

    text-align: center;

    .petition-link {
      color: white;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      transition: 0.2s;

      &:hover {
        color: blue;
        border-bottom: 1px solid darkgreen;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .petition-component{
    width: 100vw;
  }
}

.petition-date {
  color: #fff;
}
