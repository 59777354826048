.mobileSidebar{
  width: 100vw;
  min-height: 100vh;
  height: auto;
  z-index: 10;
  background-color: lightgray;
  position: absolute;
  display: block;
  transition: .3s ease-in-out all;

  display: flex;
  flex-direction: column;
  align-items: center;

  .link{
    margin-bottom: 10px;
    height: 50px;
    width: 100%;
    max-width: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: black;
    font-size: 28px;
    text-decoration: none;
    border-bottom: 1px solid black;
  }
}

.mobileSidebar.collapse{
  width: 0vw;

  .link{
    display: none;
  }
}