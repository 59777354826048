
.contact-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactWrapper {
  margin-top: 2.4em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .contactWrapper {
    grid-template-columns: 1fr;
  }
}

.contactBox {
  width: 260px;
  height: 300px;
  background-color: #707070;
  border: 1px solid #111;
  margin-top: 15px;
}

.holder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactImage {
  margin-top: -50px;
  width: 80%;
  height: auto;
  object-fit: cover;
  border: 1px solid #111;
}

.contactInfoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.email {
  font-size: 40px;
  color: white;
  transition: 0.3s;
  border-bottom: 1px solid transparent;

}

.email:hover {
  font-size: 40px;
  color: lightblue;
  border-bottom: 1px solid lightblue;
}

.contactTitle {
  text-align: center;
  margin-top: 40px;
  font-size: 25px;
  color: white;
}

.contactFormComponentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}