// Galaxy s8
@media only screen and (max-width: 600px) {
  .content {
    display: flex !important;
    width: 100vw !important;

    .createGroupBtn {
      width: 94% !important;
      margin-bottom: 3px !important;

      button {
        font-size: 15px !important;
        width: 9em !important;
        height: 2.1em !important;
      }
    }
    
    .joinGroup {
      height: 91% !important;
      width: 94% !important;

      .groupsNameSearchbar {
        height: 2em !important;
        width: 100% !important;
        max-width: 100% !important;

        .groupsName {
          margin-top: -7px !important;
          font-size: 21px !important;
          margin-left: 1px !important;
          margin-right: 42.9% !important;
        }

        input {
          margin-left: 0px !important;
          margin-bottom: 5px !important;
          width: 36% !important;
          height: 21px !important;
        }
      }

      .lighthousenow {
        overflow-y: scroll !important;
      }

      .group {
        height: 14.4% !important;
        width: 97% !important;
        margin-left: 4px !important;

        .leaderDescription {
          width: 100% !important;

          .leader {
            width: 6em !important;
            font-size: 19px !important;
            margin-right: 1px !important;
            margin-bottom: -2px !important;
          }

          .description {
            font-size: 13px !important;
            width: 97% !important;
            margin-left: 3px !important;
            margin-top: 4px !important;
            margin-right: 0px !important;
          }
        }

        button {
          margin-left: 3px !important;
        }
      }

      .pagePaginationWrapper {
        width: 100% !important;
        height: 2em !important;
      }
    }
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .createGroupBtn {
    display: flex;
    justify-content: flex-end;
    width: 43em;
    margin-bottom: 6px;

    button {
      width: 150px;
      height: 33px;
      background-color: $LG-DarkGrey;
      color: white;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .joinGroup {
    display: flex;
    flex-direction: column;
    height: 30em;
    width: 43em;
    border: 4px solid lightgray;
    border-radius: 7px;

    .groupsNameSearchbar {
      display: flex;
      align-items: center;
      width: 42.5em;
      height: 3.1em;
      background-color: lightgray;

      .groupsName {
        margin-left: 3px;
        margin-top: -14px;
        font-size: 30px;
        font-weight: 600;
      }

      input {
        outline: none;
        justify-self: flex-end;
        margin-left: 30.1em;
        margin-bottom: 3px;
        width: 13em;
        height: 26px;
      }
    }

    .joinGroupsWrapper {
      overflow-y: scroll;
      height: 100%;

      .group {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 3px solid #D2D2D2;
        height: 4.8em;

        .userCircleIcon {
          font-size: 49px;
          color: $LG-DarkGrey;
        }

        .leaderDescription {
          display: flex;
          flex-direction: column;

          .leader {
            width: 11em;
            height: 20px;
            border: none;
            outline: none;
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 19px;
            font-weight: 900;
            color: $LG-LightGreen;
            outline: none;
            margin-left: 3px;
            margin-right: 16px;
          }

          .description {
            width: 41em;
            height: 20px;
            border: none;
            color: $LG-DarkGrey;
            font-weight: 600;
            outline: none;
            margin-left: 3px;
            margin-right: 6px;
          }
        }

        button {
          margin-left: .3em;
          font-size: 27px;
          margin-top: 3px;
          background-color: transparent;
          color: $LG-DarkGrey;
          outline: none;
        }
      }
    }

    .pagePaginationWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42.5em;
      height: 3em;
      background-color: lightgray;

      .pagePagination {
        display: flex;
        align-items: center;

        .changePage-icon {
          color: #555555;
          font-size: 20px;
          margin-top: 4px;

          &:hover {
            color: darkgray;
          }
        }

        .changePage-icon.disabled {
          color: darkgray;
        }

        .itemsShowing {
          display: flex;
          color: #555555;
          margin-left: 11px;
          margin-right: 11px;
          margin-top: 3px;
        }
      } 
    }
    
    .noGroups {
      margin: 6px;
      font-weight: bold;
      font-size:17px;
    }
  }
}