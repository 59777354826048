.petitionApp {
  * {
    padding: 2px;
    max-width: 100vw;
    margin: 0 5px;
  }
}

#petitionHeader {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  font-weight: 700;
  padding-left: 9px;
}

.petitionTitle {
  align-self: center;
  font-weight: bold;
  text-align: center;
}

.petitionTopText {
  font-size: 1.2em;
  font-weight: 700;
}

.petitionS {
  font-size: 1.2em;
  font-weight: 700;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2em;
}

.errorMessage {
  color: red;
  margin-top: -7px;
}

.pleaseSignBelow {
  margin-top: 16px;
  font-size: 27px;
  font-weight: 700;
}

.signatureCanvas {
  border: 2px solid black;
  width: 500px;
  height: 200px;
}

.petitionInput input {
  width: 230px;
  height: 40px;
  border: 2px solid #111;
  outline: none;
}

.petitionInput input::placeholder {
  font-size: 18px;
  text-align: center;
  font-weight: 700;
}

.petitionBtn {
  width: 100px;
  height: 30px;
  border: 2px solid #111;
  outline: none;
  transition: 0.3s;
}

.petitionBtn:hover {
  background-color: #aaa;
  border: 1px solid white;
  color: white;
}

@media (max-width: 44em) {
  .signatureCanvas {
    border: 1px solid black;
    width: 300px;
    height: 200px;
  }

  .petitionInput {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .petitionInput input {
    width: 300px;
    margin: 20px;
    height: 40px;
    border: 1px solid #111;
    outline: none;
  }

  .petitionBtn {
    margin: 10px;
    width: 100px;
    height: 30px;
    border: 1px solid #111;
    outline: none;
  }
}

@media only screen and (max-width: 600px) {
  .petitionTopText {
    width: 19.4em !important;
    margin-left: -12px;
  }

  .errorMessage {
    margin-top: 8px;
    margin-bottom: -7px;
  }

  .pleaseSignBelow {
    margin-bottom: -11px;
    margin-top: 18px;
  }

  .petitionInput input {
    margin-top: -4px;
  }

  .signatureCanvas {
    margin-top: -25px;
  }
}

// iPhone 5/SE
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) 
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
  .petitionTopText {
    width: 16.6em !important;
  }

  .bottomContainer {
    margin-left: 1px;
  }
}

@media (max-width: 44em) {
  .petitionHeader {
    width: 90%;
    padding: 20px;
    font-size: 15px;
  }
}
