.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  h1 {
    font-size: 3em;
  }

  p {
    margin-top: 0px;
    font-size: 1.5em;
  }

  a {
    text-decoration: none;
    color: green;
    font-size: 1.2em;
  }

  img {
    width: 40%;
  }
}

@media only screen and (max-width: 700px) {
  .main {
    img {
      width: 90%;
    }

    h1 {
      font-size: 2em;
    }

    p {
      font-size: 1.2em;
    }

    a {
      font-size: 1em;
    }
  }
}
