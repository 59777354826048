.navbar {
  width: 100%;
  max-width: 100vw;
  margin-bottom: 5px;

  // height: 300px;

  // display: grid;
  // grid-template-rows: 1fr 1fr;

  .navbar-top {
    display: grid;
    grid-template-columns: 3fr 2fr 3fr;

    width: 100%;
    height: 50px;

    .toggle {
      align-self: left;

      width: 50px;
      height: 50px;

      font-size: 20px;

      background-color: inherit;

      @media (min-width: 500px) {
        visibility: hidden;
        // display: none;
      }
    }

    .brand {
      display: grid;
      align-items: center;
      // justify-content: center;

      grid-template-columns: 1fr 1fr 1fr;

      text-align: center;

      .desktop-only {
        font-size: 20px;

        @media (max-width: 500px) {
          visibility: hidden;
        }
      }

      .brand-logo {
        height: 50px;
        margin: auto;
      }
    }
  }

  .navbar-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 500px) {
      grid-template-columns: unset;
      position: relative;
      justify-content: flex-start;
    }

    .navlinks-container {
      width: 100%;
      max-width: 800px;
      display: flex;
      justify-content: space-around;

      @media (max-width: 500px) {
        display: none;
      }

      .link {
        text-decoration: none;
        color: green;

        display: flex;
        align-items: center;
      }
    }

    &.navbar-active {
      @media (max-width: 500px) {
        .navlinks-container {
          position: absolute;
          display: block;

          z-index: 1;

          background-color: whitesmoke;

          width: 50vw;

          padding: 10px;

          .link {
            display: block;
            width: 100%;
            text-align: center;
            padding: 15px 10px;
          }
        }
      }
    }

    .exchange-rate {
      display: grid;
      grid-template-rows: 1fr 1fr;

      // border-left: 1px solid black;
      margin: 5px 10px;

      .exchange-rate-top,
      .exchange-rate-bottom {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        text-align: center;

        div {
          padding: 2px 0;
          // border-right: 1px solid;
        }
      }

      .exchange-rate-bottom {
        border-top: 1px dashed black;
      }
    }
  }
}
