.image-uploader {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: grid;
  grid-template-rows: 50px 1fr;

  .im-header {
    display: flex;
    justify-content: space-between;
    .im-title {
      text-decoration: underline;
      font-size: 18px;
      font-weight: 500;
    }

    .im-add-button {
      background-color: transparent;
      height: 40px;
      width: 50px;
      color: $LG-AccentGreen-1;
      transition: 0.3s ease all;
      font-size: 30px;

      &:focus{
        outline: none;
      }

      &:hover {
        font-size: 35px;
      }
    }
  }

  .im-body {
    width: 100%;
    height: 100%;
    background-color: #eeeded;
    border: 2px solid darkgray;
    border-radius: 5px;
    padding: 5px;

    .uploader-container {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 575px;
      border-radius: 5px;
      border: 3px solid darkgray;
      border-style: dashed;
      padding: 5px;
      overflow-y: scroll;

      .images-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 0 10px;
        grid-auto-rows: 10px;
        height: 100%;
        
        .iu-image-container {
          position: relative;
          .iu-image {
            width: 100%;
          }
          .iu-button{
            position: absolute;
            top: 5px;
            background-color: transparent;

            &:focus{
              outline: none;
            }

            .iu-icon{
              color: white;
              font-size: 25px;

              &:hover{
                color: $LG-AccentGreen-1
              }
            }
          }
        }
      }

      .upload-button {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        top: 80%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1;

        width: 200px;
        height: 50px;
        background-color: white;
        font-size: 18px;
        border: 1px solid darkgray;
        border-radius: 5px;

        &:hover {
          opacity: 70%;
        }

        &:focus {
          outline: none;
        }
      }

      .im-icon {
        font-size: 20px;
      }
    }
  }
}
