.admin-calendar-category-modal {
  width: fit-content !important;
  height: fit-content !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  padding: 35px;

  p {
    text-align: center;
  }

  .category-modal-header {
    font-size: 24px;
    color: black;
  }

  .input-container {
    color: black;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    input, textarea {
      font-size: 14px;
      width: 300px;
      border-radius: 5px;
      border: 1px solid grey;
      padding: 10px 10px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
        Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    }

    input {
      height: 35px;
    }
  }
}

.admin-calendar-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .admin-calendar-button {
    width: 150px;
    height: 35px;
    border-radius: 3px;
    background-color: $LG-AccentGreen-2;
    font-size: 16px;
    color: #f4f5fa;
    transition: 0.2s ease all;
    margin: 15px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

td.admin-calendar-button-wrapper {
  display: table-cell;
}

.cancel.admin-calendar-button {
  background-color: $LG-Grey;
  color: black;
}

.delete-confirm-buttons-wrapper {
  display: flex;
  row-gap: 10px;
}
