.Modal {
  position: fixed;
  height: 650px;
  max-height: 85%;
  width: 700px;
  max-width: 90%;
  // border: 2px solid black;
  border-radius: 4px;
  padding: 5px;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  color: #595959;

  outline: none;

  z-index: 20;

  @import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
  font-family: "Inconsolata", serif;

  @include desktop {
    height: 1000px;
    max-height: 80%;
    width: 1800px;
    max-width: 80%;
  }

  .modal-header {
    padding: 15px;
    width: 100%;
    height: 10%;
    border-bottom: 1px solid black;

    .modal-title {
      font-size: 25px;
      margin-top: -3px;
      // text-align: justify;
    }

    .saved {
      color: $LG-LightGreen;
      position: absolute;
      top: 23px;
      right: 0;
      margin-right: 57px;
    }

    .saving {
      color: red;
      position: absolute;
      top: 23px;
      right: 0;
      margin-right: 57px;
    }

    .modal-close {
      margin-top: 15px;
      margin-right: 15px;
      position: absolute;
      top: 0;
      right: 0;

      background: transparent;
      border: none;
      font-size: 25px;

      // border: 1px solid black;
      // border-radius: 5px;
      padding: 0px 5px;

      &:hover {
        opacity: 60%;
      }
    }
  }

  .modal-body {
    height: 80%;
    max-height: 490px;
    overflow-y: auto;

    padding: 10px;

    @media (max-height: 700px) {
      max-height: 430px;
    }
    @media (max-height: 600px) {
      max-height: 350px;
    }

    @include desktop {
      max-height: 750px;
    }
  }

  .modal-footer {
    margin: 5px auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 90%;
      height: 90%;
      transition: 0.3s ease all;
      &:hover {
        background-color: darkgray;
      }
    }
  }
}

.Modal.Modal-sm{
  width: 500px;
  height: auto;
}

.Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.65);

  z-index: 2;
}


.Modal-BP {
  position: fixed;
  height: auto;
  max-height: 85%;
  width: 500px;
  max-width: 90%;
  // border: 2px solid black;
  border-radius: 4px;
  padding: 5px;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

  color: #595959;

  outline: none;

  z-index: 2;

  @import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
  font-family: "Inconsolata", serif;

  @include desktop {
    height: auto;
    max-height: 80%;
    width: 500px;
    max-width: 80%;
  }

  .modal-header {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid black;
    display: grid;
    grid-template-columns: 15% 1fr 15%;
    align-items: center;

    .modal-title {
      font-size: 25px;
      text-align: center;
      color: $LG-AccentBlue-2;
    }

    .bp-modal-button{
      color: $LG-AccentBlue-2;
      font-size: 14px;
      background-color: transparent;
      border: none;
      height: 20px;

      &:disabled{
        color: $LG-AccentBlue-1;
      }
    }


  }

  .modal-body {
    height: 80%;
    max-height: 490px;
    overflow-y: auto;

    padding: 10px;

    @media (max-height: 700px) {
      max-height: 430px;
    }
    @media (max-height: 600px) {
      max-height: 350px;
    }

    @include desktop {
      max-height: 750px;
    }
  }
}

.maps-modal-header{
  display: flex;
  justify-content: space-between;
}

.maps-modal-body{
  .maps-form{
    padding: 15px;

    .maps-input-container{
      height: 35px;
      display: grid;
      align-items: center;
      grid-template-columns: 25% 75%;
      margin-bottom: 10px;
      label{
        font-weight: bold;
      }
      input{
        height: 100%;
        border-radius: 5px;
      }
    }
  }
}