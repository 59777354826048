.sq-form-container{
    display: grid;
    grid-template-columns: 1fr 50% 1fr;
    grid-template-rows: 20% 1fr 20%;

    .sq-form-title{
        grid-column: 2;
        grid-row: 1;
    }

    .sq-payment-form{
        grid-column: 2;
        grid-row: 2;
    }
}


iframe {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  button {
    border: 0;
  }
  
  
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  
  #form-container {
    position: relative;
    width: 380px;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5;
    text-transform: uppercase;
  }
  
  .third {
    float: left;
    width: calc((100% - 32px) / 3);
    padding: 0;
    margin: 0 16px 16px 0;
  }
  
  .third:last-of-type {
    margin-right: 0;
  }
  
  /* Define how SqPaymentForm iframes should look */
  .sq-input {
    box-sizing: border-box;
    border: 1px solid #E0E2E3;
    border-radius: 4px;
    outline-offset: -2px;
    display: inline-block;
    -webkit-transition: border-color .2s ease-in-out, background .2s ease-in-out;
       -moz-transition: border-color .2s ease-in-out, background .2s ease-in-out;
        -ms-transition: border-color .2s ease-in-out, background .2s ease-in-out;
            transition: border-color .2s ease-in-out, background .2s ease-in-out;
  }
  
  /* Define how SqPaymentForm iframes should look when they have focus */
  .sq-input--focus {
    border: 1px solid #4A90E2;
    background-color: rgba(74,144,226,0.02);
  }
  
  
  /* Define how SqPaymentForm iframes should look when they contain invalid values */
  .sq-input--error {
    border: 1px solid #E02F2F;
    background-color: rgba(244,47,47,0.02);
  }
  
  #sq-card-number {
    margin-bottom: 16px;
  }

  .sq-payment-button{
    
    button{
      width: 100%;
      height: 56px;
      margin-top: 10px;
      background: #4A90E2;
      border-radius: 4px;
      cursor: pointer;
      display: block;
      color: #FFFFFF;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      letter-spacing: 0;
      text-align: center;
      -webkit-transition: background .2s ease-in-out;
         -moz-transition: background .2s ease-in-out;
          -ms-transition: background .2s ease-in-out;
              transition: background .2s ease-in-out;
    }

    button:hover {
      background-color: #4281CB;
    }
  }
  
  #error {
    width: 100%;
    margin-top: 16px;
    font-size: 14px;
    color: red;
    font-weight: 500;
    text-align: center;
    opacity: 0.8;
  }