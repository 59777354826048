.profileBreadcrumbs {
  display: flex;
  justify-content: center;

  margin: 10px 0px;

  .breadcrumbsContainer {
    width: 95%;
    max-width: 800px;

    font-size: 22px;
    font-weight: 500;
  }
}

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 20px;

  .profileWrapper:nth-child(1) {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .profileWrapper:nth-child(2) {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .profileWrapper {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

    width: 95%;
    max-width: 800px;

    padding: 10px;

    .topCard {
      display: flex;
      flex-direction: column;

      .bannerImgContainer {
        .bannerImg {
          height: 140px;
          width: 100%;

          object-fit: cover;
        }
      }

      .profileInfoContainer {
        display: flex;
        justify-content: space-between;

        .nameAndprofilePictureContainer {
          width: 120px;

          .profilePictureContainer {
            position: relative;

            .profilePicture {
              height: 120px;
              width: 120px;
              border-radius: 10000px;

              object-fit: cover;
            }

            .editProfilePictureBtn {
              position: absolute;
              bottom: 2px;
              right: 2px;

              background-color: transparent;
              color: #1bbaed;

              font-size: 20px;
            }
          }

          .profileName {
            text-align: center;
            font-weight: 500;
          }
        }

        .subscriptionInfoContainer {
          display: flex;
          flex-direction: column;
          align-items: end;

          .subscriptionStatusWrapper {
            font-weight: 500;
            font-size: 18px;

            @media only screen and (max-width: 420px) {
              font-size: 16px;
            }

            .statusText {
            }

            .statusText__active {
              color: #18c535;
            }

            .statusText__inactive {
              color: rgb(202, 23, 23);
            }
          }
        }

        .manageSubscriptionBtnContainer {
          margin-top: 15px;

          .manageSubscriptionBtn {
            background-color: #1caf5f;
            color: white;

            text-decoration: none;
            font-weight: 500;

            padding: 5px 10px;
            border-radius: 5px;

            transition: 120msw;

            &:hover {
              background-color: #1eb964;
            }
          }
        }
      }
    }

    .profileForm {
      margin-top: 10px;

      .allInputsContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        gap: 0px 10px;

        .inputContainer {
          position: relative;
          margin-top: 20px;

          .inputTitle {
            position: absolute;
            top: -8px;
            left: 10px;

            color: grey;
            background-color: white;

            padding: 0px 5px;

            font-size: 14px;
            font-weight: 500;
            line-height: 0.8;
          }

          input {
            width: 100%;
            padding: 6px 8px;
            border-radius: 5px;

            border: 1px solid lightgray;
            border-bottom-width: 2px;

            font-size: 16px;
          }
        }
      }

      .saveBtnContainer {
        display: flex;
        justify-content: center;

        margin-top: 20px;

        .saveBtn {
          width: 80%;
          max-width: 200px;

          padding: 8px 0px;

          background-color: #1caf5f;
          color: white;

          font-size: 16px;
          font-weight: 500;

          transition: 120ms;

          &:hover {
            background-color: #1eb964;
          }
        }
      }
    }

    .rowAddPhoto {
      display: none;
    }

    .rowAddPhotoShow {
      position: relative;

      display: inline;

      .closeBtn {
        position: absolute;
        top: 0;
        right: 0;

        background-color: transparent;

        font-size: 20px;
      }

      .profileImageUpload {
        background-color: lightgray;
        border-radius: 5px;

        padding: 10px;

        .uploadPictureContainer {
          .uploadProfilePictureBtn {
            text-align: center;

            background-color: black;
            color: white;

            max-width: 140px;
            padding: 8px 12px;

            font-size: 16px;
            font-weight: 500;
          }
        }

        .fullImageSection {
          display: flex;
          flex-direction: column;
          align-items: center;

          .fullImageAndBtnContainer {
            display: flex;
            flex-direction: column;
            align-items: center;

            .uploadedImage {
              max-width: 400px;
              max-height: 400px;
            }

            .cropBtn {
              margin-top: 10px;

              background-color: #444444;
              color: white;

              padding: 6px 30px;

              font-weight: 500;

              transition: 120ms;

              &:hover {
                background-color: #505050;
              }
            }
          }

          .imagePreviewContainer {
            margin-top: 20px;

            .sectionTitle {
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 10px;
            }

            .imageAndBtnContainer {
              display: flex;
              flex-direction: column;
              align-items: center;

              .croppedImg {
                height: 120px;
                width: 120px;
                border-radius: 10000px;

                object-fit: cover;
              }

              .saveBtn {
                margin-top: 10px;

                background-color: #1caf5f;
                color: white;

                padding: 6px 30px;

                font-weight: 500;

                transition: 120ms;

                &:hover {
                  background-color: #1eb964;
                }
              }
            }
          }
        }
      }
    }

    .topCardHide {
      display: none;
    }
  }
}
