.representative-form {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    margin: 10px;
  }
  h3 {
    text-align: justify;
  }

  .checkbox-container {
    padding: 20px 0px;

    @media (max-width: 44em) {
      grid-template-columns: 1fr;
    }

    .checkbox-wrapper {
      padding: 10px;
      margin: 10px;

      font-size: 20px;

      // border: 1px solid black;

      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
      border-radius: 10px;

      @media (max-width: 44em) {
        padding: 20px 15px;
      }

      input {
        margin: 0 10px;

        width: 15px;
        height: 15px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        list-style-position: inside;

        li {
          margin-right: 5%;
          margin-left: 5%;
        }
      }
    }
  }

  .expirationDate {
    text-align: center;
  }

  .input-container {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px 0px;

    @media (max-width: 44em) {
      grid-template-columns: 1fr;
    }
    .input-wrapper {
      padding: 10px;
      margin: 10px;
      text-align: center;

      font-size: 20px;

      // border: 1px solid black;

      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
      border-radius: 10px;

      @media (max-width: 44em) {
        padding: 20px 15px;
      }

      input {
        width: 90%;
        margin: 10px;
        max-height: 100%;
        height: 35px;
      }
    }
  }

  .confirmation {
    padding: 10px;
    margin: 10px;

    font-size: 20px;

    input[type="checkbox"] {
      width: 30px;
      height: 30px;
      -webkit-transform: scale(1.2, 1.2);
      transform: scale(1.2, 1.2);

      @media (max-width: 44em) {
        // yeah I don't know why these need to be set so high.
        width: 100px;
        height: 40px;
      }

      margin-right: 15px;
    }
  }

  .submit-wrapper {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .submit {
      border-radius: 25px;
      padding: 10px 20px;
      height: 50px;
      width: 100px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .checkbox-wrapper {
    display: flex;

  }

  .checkbox-wrapper input {
    width: 10px;
    height: 10px;
    transform: scale(3);
  }
}