.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.link-cards {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;

  text-align: center;
}

.card {
  background-color: white;
  width: 300px;
  height: 400px;
  text-align: center;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

.card-titlee {
  font-size: 40px;
  background-color: #313639;
  padding: 1px;
  width: 100%;
  color: #ffffff;
  border-radius: 10px 10px 0px 0px;
}

.card-link {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-radius: 10px;
  width: 100px;
}

.card-link:hover {
  filter: brightness(90%);
}

.card-link a {
  text-decoration: none;
  color: #222;
  font-weight: 700;
  border-bottom: 1px solid #222;
}

.card-link a:hover {
  color: #111;
}

.card-content {
  // font-size: 20px;
  width: 100%;
  height: 100%;
  padding: 5px;
  overflow: hidden;
}

// Phone
@media (max-width: 44em) {
  .home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }

  .link-cards {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .card {
    width: 200px;
    height: 250px;
  }

  .card-titlee {
    font-size: 20px;
  }

  .card-content {
    font-size: 10px;
  }
}

@media (max-width: 44em) {
  .card-link {
    font-size: 15px;
  }

  .card-link {
    height: 20px;
    width: 80%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 2px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card2 {
    background-color: cadetblue;
    width: 100px;
    height: 150px;
  }

  .card-title2 {
    font-size: 15px;
    background-color: darkcyan;
  }

  .card-link2 a {
    font-size: 15px;
  }

  .card-content2 {
    font-size: 10px;
  }

  .card-link4 {
    font-size: 10px;
  }

  .ccLink {
    font-size: 10px;
  }
}

.link-cardsA {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardA {
  background-color: white;
  width: 85%;
  height: 47 0px;
  text-align: center;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

.card-titleeA {
  font-size: 40px;
  background-color: #313639;
  padding: 1px;
  width: 100%;
  color: #ffffff;
  border-radius: 10px 10px 0px 0px;
}

.card-linkA {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-radius: 10px;
  width: 100px;
}

.card-linkA:hover {
  filter: brightness(90%);
}

.card-linkA a {
  text-decoration: none;
  color: #222;
  font-weight: 700;
  border-bottom: 1px solid #222;
  padding: 10px;
}

.card-linkA a:hover {
  color: #222;
}

.card-contentA {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding: 50px;
}

@media (max-width: 44em) {
  .cardA {
    background-color: white;
    width: 95%;
    height: 610px;
    text-align: center;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
  }

  .card-titleeA {
    font-size: 40px;
    background-color: #313639;
    padding: 1px;
    width: 100%;
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
  }

  .card-linkA {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 4px;
    border-radius: 10px;
    width: 100px;
  }

  .card-linkA:hover {
    filter: brightness(90%);
  }

  .card-linkA a {
    text-decoration: none;
    color: #222;
    font-weight: 700;
    border-bottom: 1px solid #222;
  }

  .card-linkA a:hover {
    color: #222;
  }

  .card-contentA {
    font-size: 15px;
    width: 100%;
    height: 100%;
    padding: 5px;
    overflow: hidden;
  }
}

.CLH {
  text-transform: capitalize;
}

.calenderWrapper {
  width: 80%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  text-align: center;
  height: calc(fit-content + 100px);
}

.calender {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calenderTitle {
  font-size: 30px;
}

.calenderBox {
  width: 100px;
  height: 100px;
  border: 1px solid #111;
  transition: 0.5s;
  margin: 5px;
  overflow: hidden;
}

.calenderBox:hover {
  width: 100px;
  height: 100px;
  // margin: 0px;
}

.claenderEvent {
  font-size: 12px;
  margin-top: 15px;
}

.calenderYear {
  font-size: 25px;
}

.calenderMonth {
  font-size: 25px;
}

.claenderEventT {
  margin-top: 15px;
}
