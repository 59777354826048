.groups-wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.groups {
  height: 100%;
  width: 100%;
  max-width: 800px;
  padding: 10px;

  .upper{
    height: 20%;
  }

  .lower{
    height: 80%;
    max-height: 700px;
    display: flex;
    justify-content: center;
    // flex-wrap: wrap;
    // align-items: center;
    width: 100%;
  }

  .groupChat, .groupChatS,
  .groupParticipants {
    // margin: 5px;
    border: 2px solid black;
    background-color: #e9e5e5;
    // max-height: 650px;
    width: 44%;
    // min-width: 400px;
  }

  .group {
    height: 100%;

    .description {
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      .desc-header {
        font-size: 22px;
        font-weight: 600;
        height: 35px;
      }

      .desc-body {
        height: auto;
        width: 90%;
        background-color: #e9e5e5;
        font-weight: 600;
        border: 1px solid #111;
        padding: 10px;
        font-size: 13px;

        .inner-desc {
          background-color: white;
          padding: 10px;
          border-radius: 3px;
          border: 0.3px solid #aaa;
        }

        textarea {
          width: 100%;
          height: 90%;
          resize: none;
          overflow-y: scroll;
          outline: none;
          padding: 3px;
        }
      }
    }
  }

  .groupChat {
    border: none;
  }

  .groupParticipants {
    padding: 5px;
    
    .gp-header {
      font-size: 18px;
      font-weight: 600;
      height: 5%;
    }
  }
}

.chatWindow {
  height: 100%;
  width: 100%;
  background: rgb(233, 229, 229);
  border-radius: 5px;
  display: grid;
  grid-template-rows: 1fr 50px;

  .chatInputWrapper {
    padding: 5px;
    display: flex;
    align-items: flex-end;

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid transparent;
      background-color: white;
      border-radius: 20px;

      input {
        flex-grow: 2;
        width: 100%;
        min-height: 35px;
        padding: 0 5px;
        border: 1px solid transparent;
        background-color: transparent;
        border-radius: 20px;

        &:focus {
          outline: none;
        }
      }

      button{
        background: transparent;
        border: none;
        width: auto;
        height: auto;
        margin-right: 10px;
      }

      .inputIcon{
        font-size: 22px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .chat {
    // max-height: 600px;
    height: 100%;
    list-style: none;
    background: none;
    margin: 0;
    padding: 0;
    overflow: scroll;
    scroll-behavior: smooth;

    li {
      padding: 5px;
      overflow: hidden;

      p {
        font-size: 14px;
        margin: 0 0 0.2rem 0;
        color: gray;
      }

      .timeStamp {
        text-align: end;
        padding-right: 10px;
      }

      .info{
        display: flex;
        justify-content: flex-end;

        .options{
          margin-right: 5px;
        }
      }
    }

    .self {
      justify-content: flex-end;
      align-items: flex-end;

      p {
        text-align: end;
      }

      .msg {
        background-color: #2e8bef;
        border-bottom-right-radius: 0;

        .message {
          color: white;
        }
      }
    }

    .other {
      .msg {
        border-bottom-left-radius: 0px;
      }
    }

    .bottom{
      height: 0px;
    }

    .msg {
      background: white;
      min-width: fit-content;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
    }
  }
}

.participants{
  width: 100%;
  height: 95%;

  .participant{
    width: 100%;
    height: 10%;
    border-bottom: 1px solid black;

    display: flex;
    align-items: center;

    .icon{
      margin: 0px 10px;
      font-size: 22px;
    }

    .name{
      width: 100%;
      font-size: 20px;
    }

    .adminOptions{
      display: flex;

      .icon{
        font-size: 16px;
        color: gray;
      }
    }
  }
}

.groupParticipantsHeader {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}


// Add Participants Style
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal-box {
  background-color: whitesmoke;
  width: 80vw;
  height: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
}

.modal-openned {
  position: fixed;
  top: 9%;
  left: 87.5%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  z-index: 1;
  transition: 0.2s;
  // background-color: #aaa;
  padding-top: 3px;
  transform: rotate(46deg);
}

.modal-openned:hover {
  color: red;

}

.btn-open-modal {
  padding-top: 2px;
  cursor: pointer;
  font-size: 20px;
  transition: 0.2s;

}

.btn-open-modal:hover {
  color: green;
}

.modal-header {
  font-weight: 800;
  font-size: 15px;
}

.handleUserWrapper {
  width: 100%;
  height: 30px;
  border: 1px solid #aaa;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 3px;
  margin: 5px;
}

.handleUserWrapperNames {
  width: 100%;
  height: 30px;
  border: 1px solid #aaa;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 3px;
  margin: 5px;
  background-color: #aaa;
}

.handleUser {
  font-size: 20px;
  font-weight: 800;
}

.handleUserButton {
  font-size: 20px;
  font-weight: 900;
  text-align: right;
}

.SearchUserParticipants {
  width: 90%;
  padding: 10px;
  border: none;
  outline: none;
  border: 1px solid #111;
  border-radius: 20px;
}

.SearchUserParticipantsTitle {
  font: 30px;
  font-weight: 800;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.settings {
  display: flex;
  justify-content: center;
  
  button {
    width: 135px;
    border: 2px solid black;
    cursor: pointer;
    background: transparent;
    font-size: 18px;
    transition: 0.3s;                                           
  }

  button:hover {
    background-color: red;
    color: white;
  }
}

.Gbtn-class {
  width: 0px;
  height: 0px;
  overflow: hidden;
  font-size: 20px;
  float: right;
  color: black;

  .Gbtn-icon {
    font-size: 18px;
  }
}

.desc-top {
  background-color: #e9e5e5;
  width: 90%;
  padding: 2px;
  border: 2px solid #111;
  border-bottom: 1px solid #111;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.Gbtn-class-edit-desc {
  display: flex;
  justify-content: flex-end;
}

.Gbtn-class {
  width: 30px;
  height: 30px;
  font-size: 18px;
  background-color: transparent;
}

@media only screen and (max-width: 800px) {
  .groupChat {
    width: 100vw !important;
    height: calc(100vh - 90px);
    position: absolute;
  }

  .groups {
    height: 100%;
    width: 100%;
    max-width: 800px;
    padding: 0px;
    margin: 0px;
  }

  // setting size
  .desc-header {
    background-color: #e9e5e5;
    width: 100vw;
    border: 1px solid #111;
    border-bottom: 0px solid #111;
  }

  .groupChatS {
    width: 0px !important;
    height: 0px !important;
    margin: 0px !important;
    height: 0px !important;
    border: 0px solid #111 !important;
    overflow: hidden !important;
  }

  .groupParticipants {
    width: 100vw !important;
    margin-top: 10px;
    height: 450px;
  }

  .participant {
    height: 40px !important;
  }

  .desc-body {
    width: 100vw !important;
    // height: 800px !important;
    // overflow-y: scroll;
  }

  .Gbtn-classM {
    float: right;
    background-color: transparent;
    font-size: 18px;
  }

  .description {
    width: 100vw !important;
    margin-top: 70px;
  }
}