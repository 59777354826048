.coffee-wrapper-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coffee-wrapper {
  width: 85%;
  margin-top: 50px;
}

.cTitle {
  background-color: #313639;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  color: #fff;
  text-align: center;
}

.coffee-card {
  width: 100%;

  height: fit-content;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 0px 0px 20px 20px;
}

.cDesc {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.cImages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}

.cImage {
  width: 100%;
  //   height: 350px;
  border: 1px solid #313639;
}

@media (max-width: 44em) {
  .cDesc {
    margin-bottom: 20px;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: 700;
  }

  .cImages {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
}

strong {
  font-size: 20px;
}

.cTitle {
  font-size: 40px;
}
